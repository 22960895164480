import React from 'react';
import { Text, Group, Badge, Box, useMantineTheme, Flex, ActionIcon } from '@mantine/core';
import { IconLink, IconTrash, IconFiles, IconEdit } from '@tabler/icons-react';
import { Documentation, DocumentationType } from '../../../../../models/genie';
interface UploadedDocumentationListProps {
  uploadedDocumentation: Documentation[];
  onDeleteDocumentation: (documentation: Documentation) => void;
  isEditAllowed?: boolean;
  isEditing?: boolean;
  onClickEdit: () => void;
}
const UploadedDocumentationList: React.FC<UploadedDocumentationListProps> = ({
  uploadedDocumentation,
  onDeleteDocumentation,
  isEditAllowed = true,
  isEditing = false,
  onClickEdit,
}) => {
  const theme = useMantineTheme();
  return (
    <>
      <Flex>
        <Text fz={theme.fontSizes.sm} mt={20} mb={20} fw={500} c={theme.colors.gray[6]}>
          Uploaded Documentations
        </Text>
        {isEditAllowed && !isEditing && (
          <ActionIcon
            mt={16}
            ml={10}
            variant="transparent"
            onClick={() => {
              onClickEdit();
            }}
          >
            <IconEdit />
          </ActionIcon>
        )}
      </Flex>
      {uploadedDocumentation.length > 0 && (
        <Group mb={20}>
          {uploadedDocumentation.map((documentation) => (
            <Box key={documentation.fileName} className="badge-container">
              <Badge
                leftSection={
                  documentation.type === DocumentationType.LINK ? (
                    <IconLink size="1rem" color={theme.black} />
                  ) : (
                    <IconFiles size="1rem" color={theme.black} />
                  )
                }
                rightSection={
                  isEditing ? (
                    <IconTrash
                      size="1rem"
                      color={theme.colors.red[5]}
                      onClick={() => onDeleteDocumentation(documentation)}
                      className="icon-btn"
                    />
                  ) : null
                }
                p={15}
                tt="none"
                radius="md"
              >
                {documentation.fileName}
              </Badge>
              {isEditing && (
                <Text fz={theme.fontSizes.xs} px={15}>
                  Token Count:{' '}
                  <span style={{ fontWeight: 'bold' }}>{documentation.tokenCount}</span>
                </Text>
              )}
            </Box>
          ))}
        </Group>
      )}
    </>
  );
};
export default UploadedDocumentationList;
