import { FC, ReactNode } from 'react';
import { Flex, Table as MantineTable, Text, useMantineTheme } from '@mantine/core';
import { IconClipboard } from '@tabler/icons-react';

export type TableData = string[] | number[] | ReactNode[];
export interface TableProps {
  id: string;
  tableHeader?: { label: string | ReactNode; id: string }[];
  data: TableData[];
}
const Table: FC<TableProps> = ({ id, tableHeader, data }) => {
  const theme = useMantineTheme();
  return (
    <MantineTable verticalSpacing={'md'} horizontalSpacing={'md'} id={id}>
      {tableHeader && (
        <thead>
          <tr>
            {tableHeader.map((header) => {
              return <th key={header.id}>{header.label}</th>;
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={tableHeader?.length ?? 5}>
              <Flex direction={'column'} align={'center'} gap={theme.spacing.xs}>
                <IconClipboard size={48} color={theme.colors.gray[5]} />
                <Text size="sm">No Data found</Text>
                <Flex justify={'center'} gap={theme.spacing.xs}></Flex>
              </Flex>
            </td>
          </tr>
        ) : (
          data.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((cell, cellIndex) => {
                  return <td key={cellIndex}>{cell}</td>;
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </MantineTable>
  );
};
export default Table;
