import { MantineTheme, Sx } from '@mantine/core';

export const useStoryDialogStyles = (theme: MantineTheme): Sx => {
  return {
    '.user-story-dialog': {
      width: '500px',
    },
    '.input-lable': {
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
      color: theme.colors.gray[6],
      marginTop: theme.spacing.xs,
    },
    '.input-field': {
      marginTop: theme.spacing.xs,
      width: '100%',
    },
    '.confirmationDialog__buttons': {
      width: '100%',
      justifyContent: 'flex-end',
      button: {
        width: '50%',
      },
      '&--primary button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.grubtech[0],
        },
      },
      '&--danger button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.red[6],
        },
      },
      '&--warning button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.yellow[6],
        },
      },
    },
  };
};
