export type EpicUserStory = {
  id: string | number;
  organizationId: string;
  projectId: string;
  epicId?: string;
  title: string;
  additionalInstructions: string;
  assignee: string;
  references?: Documentation[];
  userStories: UserStory[];
  ticketStatus: UserStoryStatus;
};
export type UserStoryResponse = {
  status: UserStoryResponseStatus;
  successOps: string[];
  failedOps: string[];
};
export type UserStory = {
  id: string;
  title: string;
  additionalInstructions: string;
  jiraTicketId?: string;
  assignee?: string;
  ticketStatus: UserStoryStatus;
  references?: Documentation[];
  generateTicketWithContent: boolean;
};

export type Documentation = {
  fileName?: string;
  link: string;
  type?: DocumentationType;
  tokenCount: number;
  path: string;
};

export type ScrapeContent = {
  content: string;
  fileName: string;
  link: string;
};
export type Input = {
  messages: Message[];
};
export type LangGraphConfig = {
  recursion_limit: number;
};

export type LangGraphRequest = {
  assistant_id: string;
  input: Input;
  streamMode: string[];
  langGraphConfig: LangGraphConfig;
  webhook: string;
};
export type Message = {
  role: string;
  content: string;
};

export enum DocumentationType {
  LINK = 'LINK',
  PDF = 'PDF',
  DOC = 'DOC',
  TXT = 'TXT',
  POSTMAN_COLLECTION = 'POSTMAN_COLLECTION',
  SWAGGER_FILE = 'SWAGGER_FILE',
}
export const mimeTypeMap: Map<DocumentationType, string> = new Map([
  [DocumentationType.LINK, 'text/plain'],
  [DocumentationType.PDF, 'application/pdf'],
  [
    DocumentationType.DOC,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  [DocumentationType.TXT, 'text/plain'],
  [DocumentationType.POSTMAN_COLLECTION, 'application/json'],
  [DocumentationType.SWAGGER_FILE, 'application/x-yaml'],
]);
export enum UserStoryStatus {
  NEW = 'NEW',
  UPDATED = 'UPDATED',
  CREATE_REQUESTED = 'CREATE_REQUESTED',
  JIRA_CREATED = 'JIRA_CREATED',
  JIRA_APPROVED = 'JIRA_APPROVED',
  DELETED = 'DELETED',
}
export enum UserStoryResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
}
