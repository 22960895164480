import { Sx, MantineTheme } from '@mantine/core';
export const uploadDocumentationStyle = (theme: MantineTheme, isModel: boolean): Sx => {
  return {
    '.upload-documentation-container': {
      minHeight: isModel ? '250px' : '0',
      '.upload-documentation-container__box': {
        paddingLeft: isModel ? '0px' : '20px',
      },
      '& .mantine-InputWrapper-label': {
        marginBottom: '0 !important',
        fontWeight: 'bold',
      },
    },
    '.icon-btn': {
      cursor: 'pointer',
    },
    '.badge-container': {
      '& .mantine-Badge-root': {
        backgroundColor: theme.colors.gray[4],
      },
      '& .mantine-Badge-leftSection, & .mantine-Badge-rightSection': {
        marginTop: '4px',
      },
      '& .mantine-Badge-inner': {
        color: theme.black,
        fontWeight: 500,
      },
    },
    '.file-upload-proceed-button': {
      width: '300px',
    },
    '.title': {
      fontSize: isModel ? theme.fontSizes.sm : theme.fontSizes.md,
      fontWeight: 600,
      color: isModel ? theme.colors.gray[6] : theme.colors.gray[7],
      marginTop: isModel ? theme.spacing.xs : theme.spacing.xs,
      marginBottom: isModel ? theme.spacing.xs : theme.spacing.xs,
    },
    '.confirmationDialog__buttons_container': {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    '.confirmationDialog__buttons': {
      width: isModel ? '100%' : '400px',
    },
  };
};
