import { MantineTheme, Sx } from '@mantine/core';

export const deploymentTableStyles = (theme: MantineTheme): Sx => {
  return {
    '.deployment-table': {
      // width: '100%',
      padding: 0,
      marginLeft: 0,
      marginTop: theme.spacing.md,
      backgroundColor: theme.colors.white[0],
    },
  };
};
export const deploymentDialogBoxContentStyle = (theme: MantineTheme): Sx => {
  return {
    '.dialog_container_box': {
      minHeight: '200px',
    },
    '.input-lable': {
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
      color: theme.colors.gray[6],
      marginTop: theme.spacing.xs,
    },
    '.text-lable': {
      fontSize: theme.fontSizes.sm,
      fontWeight: 400,
      marginTop: theme.spacing.xl,
      textAlign: 'center',
    },
    '.text-lable-header': {
      fontSize: theme.fontSizes.sm,
      fontWeight: 700,
      marginTop: theme.spacing.xl,
      textAlign: 'center',
    },
    '.input-field': {
      marginTop: theme.spacing.xs,
      width: '100%',
    },
    '.confirmationDialog__buttons': {
      width: '100%',
      marginTop: theme.spacing.xl,
      button: {
        width: '50%',
      },
    },
  };
};
