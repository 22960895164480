import { Modal, Box, useMantineTheme, Flex, Title } from '@mantine/core';
import { FC, ReactNode } from 'react';
import { customDialogBoxStyle } from './style';
import { IconAlertTriangle } from '@tabler/icons-react';
export interface customeDialogBoxProps {
  id: string;
  onClose: () => void;
  isOpen: boolean;
  component: ReactNode;
  title?: string;
  closeOnClickOutside?: boolean;
}
const CustomeDialogBox: FC<customeDialogBoxProps> = ({
  id,
  onClose,
  isOpen,
  component,
  title,
  closeOnClickOutside = false,
}) => {
  const theme = useMantineTheme();

  return (
    <Modal
      withCloseButton={false}
      onClose={onClose}
      opened={isOpen}
      key={id}
      centered
      size={'550px'}
      sx={customDialogBoxStyle(theme)}
      closeOnClickOutside={closeOnClickOutside}
    >
      <Flex
        className="customDialog__container"
        direction={'column'}
        justify={'center'}
        align={'center'}
      >
        {title && (
          <>
            <Box className="customeDialog__icon">
              <IconAlertTriangle width={32} height={32} color="white" />
            </Box>
            <Flex className="customeDialog__textContainer" direction={'column'} align={'center'}>
              <Title order={3}>{title}</Title>
            </Flex>
          </>
        )}
        <Box className="customeDialog__content">{component}</Box>
      </Flex>
    </Modal>
  );
};
export default CustomeDialogBox;
