import {
  Avatar,
  Text,
  Box,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  useMantineTheme,
  Button,
  Grid,
  Table,
  Flex,
  ActionIcon,
  Divider,
} from '@mantine/core';
import { FC, forwardRef, useCallback, useState } from 'react';
import { IconClipboard, IconPencil, IconRefresh, IconSearch, IconTrash } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState } from '../../../redux/reducers';
import bitbucketService from '../../../service/bitbucket.service';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  AccessLevel,
  BitBucketPermission as BucketPermission,
  RequestStatus,
} from '../../../models/bitbucket';
import { bitBucketPermissionStyle } from './style';
import { getStatusString } from '../../../utils/string';
import CustomDialogBox from '../../../components/CustomDialogBox/CustomDialogBox';
import { deploymentDialogBoxContentStyle } from '../DeploymentAndRelease/DeploymentTable/style';
import organizationService from '../../../service/organizations.service';
import { User } from '../../../models';
import { useParams } from 'react-router-dom';

export interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  email: string;
}
export interface BitBucketPermissionProps {
  repositoryUrl: string;
  integrationId: string;
}
const BitBucketPermission: FC<BitBucketPermissionProps> = ({ repositoryUrl, integrationId }) => {
  const theme = useMantineTheme();
  const { projectId } = useParams();
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const accessLevelsRequestOptions = [
    { value: AccessLevel.READ, label: 'Read' },
    { value: AccessLevel.WRITE, label: 'Write' },
  ];
  const [isEnableAddBtn, setEnableAddBtn] = useState(false);
  const [listOfData, setListOfData] = useState<BucketPermission[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<BucketPermission | null>(null);
  const [selectedPermission, setSelectedPermission] = useState<AccessLevel>(AccessLevel.EMPTY);
  const [isBitbucketPermissionDialogOpen, setIsBitbucketPermissionDialogOpen] = useState(false);
  const [isRevokePermissionDialogOpen, setIsRevokePermissionDialogOpen] = useState(false);

  const { data: userList = [], isFetching: isLoadingAllUsers } = useQuery({
    queryKey: [`load-users-for-${userData.organizationId}`, userData.organizationId],
    queryFn: () => organizationService.getCompanyUsers(userData.organizationId),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });
  const { isFetching: isLoadingBucketPermssion, refetch } = useQuery({
    queryKey: [
      `load-bitbucket-user-permission-for-${userData.organizationId}-${
        projectId ?? ''
      }-${repositoryUrl}`,
      userData.organizationId,
      projectId ?? '',
      repositoryUrl,
    ],
    queryFn: () =>
      bitbucketService.getUserPermissionList(
        userData.organizationId,
        projectId ?? '',
        repositoryUrl,
      ),
    onSuccess(data) {
      setListOfData(data);
    },
    enabled: repositoryUrl !== '',
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const permissionRequest = useMutation({
    mutationFn: (payload: BucketPermission) => {
      return bitbucketService.permissionRequest(payload);
    },
    onSuccess: (data) => {
      refetch();
      onClickClose();
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const permissionGrantedRequest = useMutation({
    mutationFn: (payload: { id: string; permission: string }) => {
      return bitbucketService.permissionGranted(payload.id, payload.permission);
    },
    onSuccess: (data) => {
      refetch();
      onClickClose();
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const revokeRequest = useMutation({
    mutationFn: (id: string) => {
      return bitbucketService.revokeRequest(id);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const checkRequestStatus = useMutation({
    mutationFn: (payload: { id: string; email: string }) => {
      return bitbucketService.checkRequestStatus(payload.id, payload.email);
    },
    onSuccess: (data) => {
      if (data) {
        refetch();
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const deleteRequest = useMutation({
    mutationFn: (payload: BucketPermission) => {
      return bitbucketService.permissionRequest(payload);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, email, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={image} radius={'100%'} />

          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              {email}
            </Text>
          </div>
        </Group>
      </div>
    ),
  );

  const onClickSync = (requestId: string, email: string) => {
    checkRequestStatus.mutate({ id: requestId, email });
  };
  const onClickEdit = (request: BucketPermission) => {
    setSelectedRequest(request);
    setIsBitbucketPermissionDialogOpen(true);
  };
  const onClickDelete = (request: BucketPermission) => {
    setSelectedRequest(request);
    setIsRevokePermissionDialogOpen(true);
  };
  const onClickClose = () => {
    setSelectedRequest(null);
    setEnableAddBtn(false);
    setIsBitbucketPermissionDialogOpen(false);
    setIsRevokePermissionDialogOpen(false);
  };

  const onClickAdd = () => {
    if (selectedRequest) {
      permissionRequest.mutate(selectedRequest);
    }
  };

  const rows = useCallback(() => {
    return (listOfData as BucketPermission[])?.map((request) => (
      <tr key={request.id}>
        <td>{request.name}</td>
        <td>{request.email}</td>
        <td>{getStatusString(request.accessLevel)}</td>
        <td>
          {request.requestedPermission === AccessLevel.EMPTY
            ? '--'
            : getStatusString(request.requestedPermission)}
        </td>
        <td>{getStatusString(request.requestStatus)}</td>
        <td>
          <Flex align={'center'} gap={theme.spacing.xl} direction={'column'}>
            <Grid>
              <Grid.Col span={4}>
                {request.accessLevel === AccessLevel.BITBUCKET_INVITATION && (
                  <ActionIcon
                    variant="transparent"
                    onClick={() => {
                      onClickSync(request.id ?? '', request.email);
                    }}
                  >
                    <IconRefresh />
                  </ActionIcon>
                )}
              </Grid.Col>
              <Grid.Col span={4}>
                <ActionIcon
                  variant="transparent"
                  onClick={() => {
                    onClickEdit(request);
                  }}
                  disabled={request.accessLevel === AccessLevel.BITBUCKET_INVITATION}
                >
                  <IconPencil />
                </ActionIcon>
              </Grid.Col>
              <Grid.Col span={4}>
                <ActionIcon
                  variant="transparent"
                  onClick={() => {
                    onClickDelete(request);
                  }}
                  disabled={
                    request.accessLevel === AccessLevel.BITBUCKET_INVITATION ||
                    request.accessLevel === AccessLevel.BITBUCKET_JOINED ||
                    (request.requestedPermission === AccessLevel.REVOKE &&
                      request.requestStatus === RequestStatus.REQUESTED)
                  }
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          </Flex>
        </td>
      </tr>
    ));
    // eslint-disable-next-line
  }, [listOfData]);

  const permissionRequestedComponent: React.ReactNode = (
    <Box sx={deploymentDialogBoxContentStyle(theme)} id="bucket-permission-request-box">
      <Box p={8} className="dialog_container_box">
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Access Level</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a permission"
              data={accessLevelsRequestOptions.map((option) => {
                return {
                  value: option.value,
                  label: option.label,
                  disabled:
                    option.value === selectedRequest?.accessLevel ||
                    option.value === selectedRequest?.requestedPermission,
                };
              })}
              onChange={(option) => {
                setSelectedPermission(option as AccessLevel);
                // if (selectedRequest) {
                //   setSelectedRequest({
                //     ...selectedRequest,
                //     requestedPermission: option as AccessLevel,
                //     requestStatus: RequestStatus.REQUESTED,
                //   });
                // }
              }}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Text className="text-lable">
          Press<span style={{ fontWeight: 600 }}> Request</span> to proceed or{' '}
          <span style={{ fontWeight: 600 }}>Cancel</span> to go back
        </Text>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`} mt={10}>
          <Button size="md" variant="outline" onClick={() => onClickClose()}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() => {
              if (selectedRequest) {
                permissionGrantedRequest.mutate({
                  id: selectedRequest.id ?? '',
                  permission: selectedPermission,
                });
              }
            }}
            variant="filled"
            disabled={selectedPermission === AccessLevel.EMPTY}
          >
            Request
          </Button>
        </Flex>
      </Box>
    </Box>
  );
  const revokeRequestedComponent: React.ReactNode = (
    <Box sx={deploymentDialogBoxContentStyle(theme)} id="bucket-permission-request-box">
      <Box p={8} className="dialog_container_box">
        <Text className="text-lable-header">
          Are you sure you want to revoke permission to {selectedRequest?.name} for the{' '}
          {repositoryUrl} project
        </Text>
        <Text className="text-lable">
          Press<span style={{ fontWeight: 600 }}> Request</span> to proceed or{' '}
          <span style={{ fontWeight: 600 }}>Cancel</span> to go back
        </Text>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`} mt={10}>
          <Button size="md" variant="outline" onClick={() => onClickClose()}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() => {
              if (selectedRequest) {
                revokeRequest.mutate(selectedRequest.id ?? '');
              }
            }}
            variant="filled"
          >
            Request
          </Button>
        </Flex>
      </Box>
    </Box>
  );
  return (
    <Box sx={bitBucketPermissionStyle(theme)} p={0} m={0}>
      <LoadingOverlay
        visible={
          isLoadingAllUsers ||
          isLoadingBucketPermssion ||
          deleteRequest.isLoading ||
          permissionRequest.isLoading ||
          checkRequestStatus.isLoading ||
          revokeRequest.isLoading ||
          permissionGrantedRequest.isLoading
        }
      />
      <Paper shadow="xs" className="bitbucket-setting-paper">
        <Grid className="bitbucket-setting-content">
          <Grid.Col span={8} className="bitbucket-search-section">
            <Select
              placeholder="Search Developer By Name,Email"
              data={
                userList.length > 0
                  ? listOfData.length > 0
                    ? userList
                        .filter((user) => listOfData.some((d) => d.email !== user.email))
                        .map((user) => {
                          return {
                            value: user.id,
                            label: user.name,
                            image: '',
                            email: user.email,
                          };
                        })
                    : userList.map((user) => {
                        return {
                          value: user.id,
                          label: user.name,
                          image: '',
                          email: user.email,
                        };
                      })
                  : []
              }
              searchable
              clearable
              allowDeselect
              nothingFound="No Developer Found"
              value={
                selectedRequest
                  ? userList.find((u) => u.email === selectedRequest?.email)?.id ?? ''
                  : ''
              }
              onChange={(option) => {
                if (option) {
                  const user = userList.find((u) => u.id === option) as User;
                  setSelectedRequest({
                    organizationId: userData.organizationId,
                    projectId: projectId ?? '',
                    integrationId: integrationId,
                    name: user.name,
                    email: user.email,
                    accessLevel: AccessLevel.BITBUCKET_INVITATION,
                    requestStatus: RequestStatus.REQUESTED,
                    requestedPermission: AccessLevel.EMPTY,
                    repoName: repositoryUrl,
                  });
                  setEnableAddBtn(true);
                } else {
                  setSelectedRequest(null);
                  setEnableAddBtn(false);
                }
              }}
              itemComponent={SelectItem}
              filter={(value, item) => {
                if (item.label) {
                  return (
                    item.label.toLowerCase().includes(value.toLowerCase()) ||
                    item.email.toLowerCase().includes(value.toLowerCase())
                  );
                }
                return false;
              }}
              icon={<IconSearch />}
              maxDropdownHeight={300}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
              className="search-bitbucket-user-box"
            />
          </Grid.Col>
          <Grid.Col offset={0.15} span={'auto'} className="user-add-button-section">
            <Button
              fullWidth
              size="sm"
              onClick={() => {
                onClickAdd();
              }}
              variant="filled"
              disabled={!isEnableAddBtn}
            >
              Add
            </Button>
          </Grid.Col>
        </Grid>
        <Grid className="user-permission-table">
          <Table verticalSpacing={'md'} horizontalSpacing={'md'}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Access Level</th>
                <th>Requested Permission</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            {listOfData?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <Flex direction={'column'} align={'center'} gap={theme.spacing.xs}>
                      <IconClipboard size={48} color={theme.colors.gray[5]} />
                      <Text size="sm">No Data found</Text>
                      <Flex justify={'center'} gap={theme.spacing.xs}></Flex>
                    </Flex>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{rows()}</tbody>
            )}
          </Table>
        </Grid>
        <CustomDialogBox
          id="bitbucket-permission-request-dialog-box"
          isOpen={isBitbucketPermissionDialogOpen}
          onClose={() => onClickClose()}
          component={permissionRequestedComponent}
          title="Grant Permission"
        />
        <CustomDialogBox
          id="bitbucket-revoke-permission-request-dialog-box"
          isOpen={isRevokePermissionDialogOpen}
          onClose={() => onClickClose()}
          component={revokeRequestedComponent}
          title="Revoke Permission"
        />
      </Paper>
    </Box>
  );
};
export default BitBucketPermission;
