import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  LoadingOverlay,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconClipboard, IconCloudUp, IconGavel, IconWorldUp } from '@tabler/icons-react';
import React, { FC, useCallback, useState } from 'react';
import { deploymentDialogBoxContentStyle, deploymentTableStyles } from './style';
import deploymentService from '../../../../service/deployment.service';
import {
  ComponentDetails,
  DeployRequest,
  Environment,
  EnvironmentResponse,
  releaseRequest,
} from '../../../../models/deployment';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '../../../../redux/reducers';
import { RootState } from '../../../../redux/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomDialogBox from '../../../../components/CustomDialogBox/CustomDialogBox';
import { formatDateTime } from '../../../../utils/string';
export enum ACTION_TYPE {
  BUILD = 'BUILD',
  RELEASE = 'RELEASE',
  DEPLOY = 'DEPLOY',
}
export interface DeploymentTableProps {
  id: string;
  env: Environment | null;
  componentId: string;
}
const DeploymentTable: FC<DeploymentTableProps> = ({ id, env, componentId }) => {
  const theme = useMantineTheme();

  const { projectId } = useParams();
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });
  const [listOfData, setListOfData] = useState<ComponentDetails[]>([]);
  const [actionType, setActionType] = useState<ACTION_TYPE | null>(null);
  const [selectedComponent, setSelectedComponent] = useState<ComponentDetails | null>(null);
  const [form, setForm] = useState<{ [key: string]: any } | null>(null);
  const [showAllBranches, setShowAllBranches] = useState('');
  const { isFetching: isLoadingDeploymentData, refetch } = useQuery({
    queryKey: [
      `load-deployment-data-for-${env?.environmentName}`,
      userData.organizationId,
      projectId,
    ],
    queryFn: () => deploymentService.getDeploymentDetails(env?.environmentId ?? '', componentId),
    enabled: env !== null && componentId !== '',
    onSuccess: (data: EnvironmentResponse) => {
      if (data) {
        setListOfData(data.componentDetails);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: branches = [], isFetching: isLoadingBranch } = useQuery({
    queryKey: [`load-branch-data-for`, selectedComponent?.repositoryUrl],
    queryFn: () => deploymentService.getBranches(selectedComponent?.repositoryUrl ?? ''),
    enabled: selectedComponent?.repositoryUrl != null && selectedComponent?.repositoryUrl !== '',
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });
  const { data: releaseVersion = [], isFetching: isLoadingReleaseVersion } = useQuery({
    queryKey: [`load-release-version`, selectedComponent?.componentId, form?.['branchId']],
    queryFn: () =>
      deploymentService.getReleaseVersion(selectedComponent?.componentId ?? '', form?.['branchId']),
    enabled:
      selectedComponent?.componentId !== null &&
      form?.['branchId'] !== undefined &&
      actionType === ACTION_TYPE.DEPLOY,
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });
  const { isFetching: isLoadingNextVersion } = useQuery({
    queryKey: [`load-next-version`, selectedComponent?.repositoryUrl, form?.['branchId']],
    queryFn: () =>
      deploymentService.getNextVersion(selectedComponent?.componentId ?? '', form?.['branchId']),
    enabled:
      selectedComponent !== null &&
      form?.['branchId'] !== undefined &&
      actionType === ACTION_TYPE.RELEASE,
    onSuccess(data) {
      setForm({
        ...form,
        nexDevelopmentVersion: data.nextDevelopmentVersion,
        releaseVersion: data.nextReleaseVersion,
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const build = useMutation({
    mutationFn: (payload: { componentId: string; branchId: string }) => {
      return deploymentService.build(payload.componentId, payload.branchId);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: (
          <Text>
            Successfully performed a build in <strong>{env?.environmentName}</strong>
          </Text>
        ),
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const release = useMutation({
    mutationFn: (payload: releaseRequest) => {
      return deploymentService.release(payload);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: (
          <Text>
            Successfully perform a release in <strong>{env?.environmentName}</strong>
          </Text>
        ),
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const deploy = useMutation({
    mutationFn: (payload: DeployRequest) => {
      return deploymentService.deploy(payload);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: (
          <Text>
            Successfully perform a deployment in <strong>{env?.environmentName}</strong>
          </Text>
        ),
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const onClickBuild = (component: ComponentDetails) => {
    setSelectedComponent(component);
    setActionType(ACTION_TYPE.BUILD);
  };
  const onClickRelease = (component: ComponentDetails) => {
    setSelectedComponent(component);
    setActionType(ACTION_TYPE.RELEASE);
  };
  const onClickDeploy = (component: ComponentDetails) => {
    setSelectedComponent(component);
    setActionType(ACTION_TYPE.DEPLOY);
  };
  const handleMoreClick = (id: string) => {
    setShowAllBranches(id);
  };
  const getLatestVersionsList = (status: string, componentId: string) => {
    if (status != null && status !== '') {
      const versions = status.split(',');
      const show = showAllBranches === componentId;
      const limit = show ? versions.length : Math.min(versions.length, 5);
      let html = '';
      for (let i = 0; i < limit; i++) {
        html += `<div>${versions[i]}</div>`;
      }
      if (versions.length > 5 && !show) {
        html += `<div class="more" style="color:blue; cursor: pointer">More...</div>`;
      }
      // if(show) {
      //   html += `<div class="back" style="color:blue; cursor: pointer"><-Back</div>`;
      // }
      return html;
    }
    return '';
  };
  const rows = useCallback(() => {
    return (listOfData as ComponentDetails[])?.map((component) => (
      <tr key={component.componentId} style={{ maxHeight: 30 }}>
        <td>{component.componentName}</td>
        <td>{component.deployedVersion}</td>
        <td
          style={{ maxWidth: 30 }}
          dangerouslySetInnerHTML={{
            __html: getLatestVersionsList(component.latestVersion, component.componentId),
          }}
          onClick={(e) => {
            if ((e.target as HTMLElement).className === 'more') {
              handleMoreClick(component.componentId);
            }
            // else if ((e.target as HTMLElement).className === 'back') {
            //   setShowAllBranches('');
            // }
          }}
        ></td>
        <td>{formatDateTime(component.deployedTime)}</td>
        <td>
          <Flex align={'center'} gap={theme.spacing.xl} direction={'column'}>
            <Grid>
              <Grid.Col span={4}>
                <Tooltip label="Build" withArrow position="bottom">
                  <ActionIcon
                    variant="transparent"
                    onClick={() => {
                      onClickBuild(component);
                    }}
                    // disabled={!component.allowBuild}
                  >
                    <IconGavel />
                  </ActionIcon>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={4}>
                <Tooltip label="Release" withArrow position="bottom">
                  <ActionIcon
                    variant="transparent"
                    onClick={() => {
                      onClickRelease(component);
                    }}
                    // disabled={!component.allowRelease}
                  >
                    <IconWorldUp />
                  </ActionIcon>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={4}>
                <Tooltip label="Deploy" withArrow position="bottom">
                  <ActionIcon
                    variant="transparent"
                    onClick={() => {
                      onClickDeploy(component);
                    }}
                    // disabled={!component.allowDeploy}
                  >
                    <IconCloudUp />
                  </ActionIcon>
                </Tooltip>
              </Grid.Col>
            </Grid>
          </Flex>
        </td>
      </tr>
    ));
    // eslint-disable-next-line
  }, [listOfData, showAllBranches]);

  const buildComponent: React.ReactNode = (
    <Box sx={deploymentDialogBoxContentStyle(theme)}>
      <Box p={8} className="dialog_container_box">
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Repository</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              className="input-field"
              placeholder="<Repository_Name>"
              value={selectedComponent?.repositoryUrl}
              disabled
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Branch</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Branch"
              data={
                branches?.map((b) => {
                  return { value: b.branchName, label: b.branchName };
                }) ?? []
              }
              onChange={(option) => {
                setForm({ ...form, branchId: option });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`}>
          <Button size="md" variant="outline" onClick={() => onClickClose()}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() =>
              build.mutate({
                componentId: selectedComponent?.componentId ?? '',
                branchId: form?.['branchId'],
              })
            }
            variant="filled"
            disabled={!form?.['branchId']}
          >
            Build
          </Button>
        </Flex>
      </Box>
    </Box>
  );
  const releaseComponent: React.ReactNode = (
    <Box sx={deploymentDialogBoxContentStyle(theme)}>
      <Box p={8} className="dialog_container_box">
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Repository</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              className="input-field"
              placeholder="<Repository_Name>"
              value={selectedComponent?.repositoryUrl}
              disabled
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Branch</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Branch"
              data={
                branches?.map((b) => {
                  return { value: b.branchName, label: b.branchName };
                }) ?? []
              }
              onChange={(option) => {
                setForm({ ...form, branchId: option });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Release Version</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              className="input-field"
              placeholder="ex: 0.1.0"
              value={form?.['releaseVersion'] ?? ''}
              onChange={(event) => {
                setForm({ ...form, releaseVersion: event.currentTarget.value });
              }}
            />
          </Grid.Col>
        </Grid>
        {selectedComponent?.componentType === 'SERVICE' && (
          <Grid pt={4}>
            <Grid.Col span={4}>
              <Text className="input-lable">Next Development Version</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput
                className="input-field"
                placeholder="ex: 0.1.0"
                value={form?.['nexDevelopmentVersion'] ?? ''}
                onChange={(event) => {
                  setForm({ ...form, nexDevelopmentVersion: event.currentTarget.value });
                }}
              />
            </Grid.Col>
          </Grid>
        )}
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Description</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              className="input-field"
              placeholder="Description"
              value={form?.['description'] ?? ''}
              onChange={(event) => {
                setForm({ ...form, description: event.currentTarget.value });
              }}
            />
          </Grid.Col>
        </Grid>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`}>
          <Button size="md" variant="outline" onClick={() => onClickClose()}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() =>
              release.mutate({
                componentId: selectedComponent?.componentId ?? '',
                componentName: selectedComponent?.componentName ?? '',
                repositoryUrl: selectedComponent?.repositoryUrl ?? '',
                branch: form?.['branchId'],
                newDevelopmentVersion: form?.['nexDevelopmentVersion'],
                releaseVersion: form?.['releaseVersion'],
                description: form?.['description'],
                released: false,
                delete: false,
                active: true,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
              })
            }
            variant="filled"
            disabled={!form?.['branchId']}
          >
            Release
          </Button>
        </Flex>
      </Box>
    </Box>
  );
  const deployComponent: React.ReactNode = (
    <Box sx={deploymentDialogBoxContentStyle(theme)}>
      <Box p={8} className="dialog_container_box">
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Branch</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Branch"
              data={
                branches?.map((b) => {
                  return { value: b.branchName, label: b.branchName };
                }) ?? []
              }
              onChange={(option) => {
                setForm({ ...form, branchId: option });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Grid pt={4}>
          <Grid.Col span={4}>
            <Text className="input-lable">Release Version</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Select
              placeholder="Select a Release Version"
              data={
                releaseVersion?.map((b) => {
                  return { value: b.releaseVersion, label: b.releaseVersion };
                }) ?? []
              }
              onChange={(option) => {
                setForm({ ...form, deployedVersion: option });
              }}
              maxDropdownHeight={150}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.grubtech[0],
                      color: theme.white,
                    },
                  },
                },
              })}
            />
          </Grid.Col>
        </Grid>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`}>
          <Button size="md" variant="outline" onClick={() => onClickClose()}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() =>
              deploy.mutate({
                componentId: selectedComponent?.componentId ?? '',
                componentName: selectedComponent?.componentName ?? '',
                environmentId: env?.environmentId ?? '',
                environmentName: env?.environmentName ?? '',
                deployedVersion: form?.['deployedVersion'],
                branch: form?.['branchId'],
                status: 'PENDING',
                delete: false,
                active: true,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
              })
            }
            variant="filled"
            disabled={!(form?.['branchId'] && form?.['deployedVersion'])}
          >
            Deploy
          </Button>
        </Flex>
      </Box>
    </Box>
  );

  const onClickClose = () => {
    setActionType(null);
    setSelectedComponent(null);
    setForm(null);
  };

  const getByTitle = () => {
    return actionType === ACTION_TYPE.BUILD
      ? `Build (${selectedComponent?.componentName})`
      : actionType === ACTION_TYPE.RELEASE
      ? `Release (${selectedComponent?.componentName})`
      : actionType === ACTION_TYPE.DEPLOY
      ? `Deploy (${selectedComponent?.componentName})`
      : '';
  };

  return (
    <Box sx={deploymentTableStyles(theme)} key={id} p={0} m={0}>
      <LoadingOverlay
        visible={
          isLoadingDeploymentData ||
          isLoadingBranch ||
          isLoadingReleaseVersion ||
          isLoadingNextVersion ||
          deploy.isLoading ||
          build.isLoading ||
          release.isLoading
        }
      />
      <Table
        className="deployment-table"
        verticalSpacing={'md'}
        horizontalSpacing={'md'}
        p={0}
        m={0}
      >
        <thead>
          <tr>
            <th>Component</th>
            <th>Deployed Version</th>
            <th>Latest Version</th>
            <th>Deployed Time</th>
            <th></th>
          </tr>
        </thead>
        {listOfData?.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={5}>
                <Flex direction={'column'} align={'center'} gap={theme.spacing.xs}>
                  <IconClipboard size={48} color={theme.colors.gray[5]} />
                  <Text size="sm">No Data found</Text>
                  <Flex justify={'center'} gap={theme.spacing.xs}></Flex>
                </Flex>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows()}</tbody>
        )}
      </Table>
      {actionType && (
        <CustomDialogBox
          id="deployment-dialog-box"
          isOpen={actionType !== null}
          onClose={() => onClickClose()}
          component={
            actionType === ACTION_TYPE.BUILD
              ? buildComponent
              : actionType === ACTION_TYPE.RELEASE
              ? releaseComponent
              : actionType === ACTION_TYPE.DEPLOY
              ? deployComponent
              : null
          }
          title={getByTitle()}
        />
      )}
    </Box>
  );
};
export default DeploymentTable;
