import { MantineTheme, Sx } from '@mantine/core';

export const previewStyle = (theme: MantineTheme): Sx => {
  return {
    '.preview-container': {
      maxHeight: '250px',
      minHeight: '250px',
      overflow: 'auto',
      border: `1px solid ${theme.colors.gray[5]} `,
      borderRadius: theme.radius.sm,
      marginTop: theme.spacing.md,
      marginBottom: theme.spacing.md,
    },
  };
};
