import { Box, Title, useMantineTheme } from '@mantine/core';
import { UserStory } from '../../../../models/genie';
import { useStoryDialogStyles } from './style';
import ResourceUploader from '../ResourceUploader';

export interface UserStoryDocumentProps {
  id: string;
  isEdit: boolean;
  isReadOnly?: boolean;
  userStory: UserStory;
  repoName: string;
  projectId: string;
  onCancel: () => void;
  refetch: () => void;
  isLoading: (isLoading: boolean) => void;
}
const UserStoryDocumentDialog: React.FC<UserStoryDocumentProps> = ({
  id,
  isEdit,
  isReadOnly,
  userStory,
  repoName,
  projectId,
  onCancel,
  refetch,
  isLoading,
}) => {
  const theme = useMantineTheme();
  return (
    <Box sx={useStoryDialogStyles(theme)} id={id}>
      <div className="user-story-dialog">
        <Title order={5}>User Story [ {userStory.title} ]</Title>
        <ResourceUploader
          repoName={repoName}
          documentations={userStory.references ?? []}
          setIsUploading={() => {}}
          projectId={projectId}
          isUploading={true}
          refetch={refetch}
          userStoryId={userStory.id}
          isEdit={isReadOnly ? false : isEdit}
          isModel={true}
          isLoading={isLoading}
          onCancel={onCancel}
        />
      </div>
    </Box>
  );
};
export default UserStoryDocumentDialog;
