import {
  Text,
  Grid,
  Select,
  FileInput,
  Button,
  TextInput,
  Box,
  useMantineTheme,
} from '@mantine/core';
import React, { useState } from 'react';
import { Documentation, mimeTypeMap, DocumentationType } from '../../../../../models/genie';
import UploadedDocumentationList from '../UploadedDocumentationList';

const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
interface UploadDocumentationProps {
  onUploadDocumentation: (
    documentation: File | string,
    type: DocumentationType,
  ) => Promise<{ status: boolean; message?: string }>;
  onDeleteDocumentation: (documentation: Documentation) => void;
  uploadedDocumentation: Documentation[];
  isModel?: boolean;
  setIsUploading: (isUploading: boolean) => void;
  globalDocumentations?: Documentation[];
}
const UploadDocumentation: React.FC<UploadDocumentationProps> = ({
  onUploadDocumentation,
  onDeleteDocumentation,
  uploadedDocumentation,
  setIsUploading,
  globalDocumentations,
  isModel = false,
}) => {
  const theme = useMantineTheme();
  const [uploadingFile, setUploadingFile] = useState<File | string | null>(null);
  const [uploadingFileType, setUploadingFileType] = useState<DocumentationType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const onUpload = (documentation: File | string | null) => {
    if (documentation && uploadingFileType) {
      if (uploadingFileType && documentation instanceof File) {
        const expectedMimeType = mimeTypeMap.get(uploadingFileType);
        if (uploadedDocumentation.some((doc) => doc.fileName === documentation.name)) {
          setError('File with same name already uploaded');
          return;
        } else if (
          globalDocumentations &&
          globalDocumentations.some((doc) => doc.fileName === documentation.name)
        ) {
          setError('File with same name already uploaded the to global documentations');
          return;
        } else if (expectedMimeType && documentation.type !== expectedMimeType) {
          setError('Please upload a valid file');
          return;
        }
      } else {
        if (!isValidURL(documentation as string)) {
          setError('Please enter valid URL');
          return;
        }
        if (uploadedDocumentation.some((doc) => doc.link === documentation)) {
          setError('Link is already uploaded');
          return;
        }
        if (
          globalDocumentations &&
          globalDocumentations.some((doc) => doc.link === documentation)
        ) {
          setError('Link is already uploaded to the global documentations');
          return;
        }
      }
      onUploadDocumentation(documentation, uploadingFileType).then((payload) => {
        if (payload.status) {
          setUploadingFile(null);
          setUploadingFileType(null);
        } else {
          setError(payload.message ?? 'Failed to upload file/link');
        }
      });
    }
  };
  const getFileAcceptTypes = (type: DocumentationType) => {
    switch (type) {
      case DocumentationType.PDF:
        return '.pdf';
      case DocumentationType.DOC:
        return '.doc,.docx';
      case DocumentationType.TXT:
        return '.txt';
      case DocumentationType.SWAGGER_FILE:
        return '.yaml,.json';
      case DocumentationType.POSTMAN_COLLECTION:
        return '.json';
      default:
        return '.pdf,.doc,.docx,.txt,.yaml,.json';
    }
  };
  const isProceedDisable = () => {
    if (uploadingFileType === null) {
      return false;
    } else {
      if (uploadingFileType === DocumentationType.LINK) {
        return (
          uploadingFile !== null && typeof uploadingFile === 'string' && uploadingFile.trim() !== ''
        );
      }
      return uploadingFile !== null;
    }
  };
  const fileNameModifier = (file: File) => {
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    const fileNameWithoutSpecialCharacters = fileNameWithoutExtension.replace(/[^a-zA-Z0-9]/g, '-');
    const modifiedFileName = `${fileNameWithoutSpecialCharacters}.${fileExtension}`;
    return new File([file], modifiedFileName, { type: file.type });
  };
  return (
    <>
      <Grid align="top" columns={12}>
        <Grid.Col span={isModel ? 12 : 6}>
          <Text fz={theme.fontSizes.sm} fw={500} c={theme.colors.gray[6]}>
            API Document Type
          </Text>
          <Select
            placeholder="Select Type"
            data={Object.keys(DocumentationType)}
            value={uploadingFileType ? uploadingFileType.toString() : null}
            onChange={(value) => {
              setUploadingFileType(value as DocumentationType);
              setUploadingFile(null);
              setError(null);
            }}
          />
        </Grid.Col>
        <Grid.Col span={isModel ? 12 : 6}>
          {uploadingFileType === DocumentationType.LINK || uploadingFileType === null ? (
            <>
              <Text fz={theme.fontSizes.sm} fw={500} c={theme.colors.gray[6]}>
                Global API Documentation
              </Text>
              <TextInput
                placeholder={
                  uploadingFileType === null ? 'Select document type first' : 'Enter URL'
                }
                value={typeof uploadingFile === 'string' ? uploadingFile : ''}
                onChange={(e) => {
                  setUploadingFile(e.target.value);
                  setError(null);
                }}
                disabled={uploadingFileType === null}
                error={error}
                rightSectionWidth={isModel ? 80 : 150}
                rightSection={
                  <Button
                    size="xs"
                    variant="filled"
                    className="epic-add-button"
                    onClick={() => onUpload(uploadingFile)}
                    disabled={uploadingFile === null || error !== null}
                  >
                    Upload
                  </Button>
                }
              />
            </>
          ) : (
            <>
              <Text fz={theme.fontSizes.sm} fw={500} c={theme.colors.gray[6]}>
                Global API Documentation
              </Text>
              <FileInput
                placeholder="Browse file"
                accept={getFileAcceptTypes(uploadingFileType)}
                value={uploadingFile instanceof File ? uploadingFile : null}
                onChange={(file) => {
                  setUploadingFile(file ? fileNameModifier(file) : file);
                  setError(null);
                }}
                error={error}
                clearable
                rightSectionWidth={isModel ? 80 : 150}
                rightSection={
                  <Button
                    size="xs"
                    variant="filled"
                    className="epic-add-button"
                    onClick={() => onUpload(uploadingFile)}
                    disabled={uploadingFile === null || error !== null}
                  >
                    Upload
                  </Button>
                }
              />
            </>
          )}
        </Grid.Col>
      </Grid>
      <UploadedDocumentationList
        uploadedDocumentation={uploadedDocumentation}
        onDeleteDocumentation={onDeleteDocumentation}
        onClickEdit={() => setIsUploading(true)}
        isEditing={true}
      />
      {!isModel && (
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={isProceedDisable()}
            onClick={() => setIsUploading(false)}
            className="file-upload-proceed-button"
          >
            Proceed
          </Button>
        </Box>
      )}
    </>
  );
};
export default UploadDocumentation;
