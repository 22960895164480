import React from 'react';
import { Box, Button, Divider, Flex, LoadingOverlay, Text, useMantineTheme } from '@mantine/core';
import UploadDocumentation from './UploadDocumentation';
import { Documentation, DocumentationType, ScrapeContent } from '../../../../models/genie';
import UploadedDocumentationList from './UploadedDocumentationList';
import { uploadDocumentationStyle } from './style';
import { useMutation } from '@tanstack/react-query';
import { userStoryService } from '../../../../service';
import { RootState } from '../../../../redux/common';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '../../../../redux/reducers';
import { notifications } from '@mantine/notifications';
import ScrapePreview from './ScrapePreview';
import { v4 as uuidv4 } from 'uuid';
interface ResourceUploaderProps {
  repoName: string;
  documentations: Documentation[];
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  projectId: string;
  userStoryId?: string;
  isEdit: boolean;
  refetch: () => void;
  isModel?: boolean;
  isLoading?: (isLoading: boolean) => void;
  onCancel?: () => void;
}
const ResourceUploader: React.FC<ResourceUploaderProps> = ({
  repoName,
  documentations,
  isUploading,
  setIsUploading,
  projectId,
  userStoryId,
  refetch,
  isEdit,
  isModel = false,
  isLoading,
  onCancel,
}) => {
  const theme = useMantineTheme();

  const { userData } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => state.authentication,
  );

  const [scrapeContent, setScrapeContent] = React.useState<ScrapeContent | null>(null);
  const [isScrapingEnabled, setIsScrapingEnabled] = React.useState<boolean>(false);
  const [scrapingError, setScrapingError] = React.useState<string | undefined>();

  const handleModelSpinner = (load: boolean) => {
    if (isLoading) isLoading(load);
  };

  function getDomainFromUrl(url: string) {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (error) {
      return uuidv4();
    }
  }

  const uploadDocumentation = useMutation({
    mutationFn: ({
      repoName,
      file,
      type,
    }: {
      repoName: string;
      file: File;
      type: DocumentationType;
    }) => {
      return userStoryService.uploadFile(
        userData.organizationId,
        projectId,
        repoName,
        file,
        type,
        userStoryId,
      );
    },
    onSuccess: () => {
      refetch();
      handleModelSpinner(false);
    },
    onError: (error) => {
      handleModelSpinner(false);
    },
  });
  const scrape = useMutation({
    mutationFn: ({ link, fileName }: { link: string; fileName: string }) => {
      return userStoryService.scrape(link, fileName);
    },
    onSuccess: (data) => {
      setScrapeContent(data);
      setIsScrapingEnabled(true);
      handleModelSpinner(false);
    },
    onError: (error) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
      handleModelSpinner(false);
    },
  });
  const uploadDocumentationLink = useMutation({
    mutationFn: (payload: ScrapeContent) => {
      return userStoryService.uploadStringContent(
        userData.organizationId,
        projectId,
        repoName,
        payload,
        DocumentationType.LINK,
        userStoryId,
      );
    },
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'Link uploaded successfully.',
        color: 'green',
      });
      refetch();
      setScrapeContent(null);
      setIsScrapingEnabled(false);
      handleModelSpinner(false);
    },
    onError: (error) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
      handleModelSpinner(false);
      setScrapingError((error as any).response?.data?.errorDescription ?? 'Failed to upload link');
    },
  });
  const deleteDocumentation = useMutation({
    mutationFn: ({ repoName, filePath }: { repoName: string; filePath: string }) => {
      return userStoryService.deleteDocumentation(
        repoName,
        userData.organizationId,
        projectId,
        filePath,
        userStoryId,
      );
    },
    onSuccess: () => {
      notifications.show({
        title: 'Success',
        message: 'Document delete successfully.',
        color: 'green',
      });
      refetch();
      handleModelSpinner(false);
    },
    onError: (error) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
      handleModelSpinner(false);
    },
  });
  const onUploadDocumentation = async (
    documentation: File | string,
    type: DocumentationType,
  ): Promise<{ status: boolean; message?: string }> => {
    handleModelSpinner(true);
    if (documentation instanceof File) {
      return await uploadDocumentation.mutateAsync({
        repoName: repoName,
        file: documentation,
        type,
      });
    } else {
      await scrape.mutateAsync({ link: documentation, fileName: getDomainFromUrl(documentation) });
      return {
        status: true,
      };
    }
  };
  const onDeleteDocumentation = (documentation: Documentation) => {
    handleModelSpinner(true);
    deleteDocumentation.mutate({
      repoName: repoName,
      filePath: documentation.path ?? '',
    });
  };
  const onClickScrapeDone = () => {
    setScrapingError(undefined);
    if (scrapeContent) {
      handleModelSpinner(true);
      uploadDocumentationLink.mutateAsync(scrapeContent);
    }
  };
  return (
    <Box sx={uploadDocumentationStyle(theme, isModel)}>
      {!isModel && (
        <LoadingOverlay
          visible={
            uploadDocumentation.isLoading ||
            scrape.isLoading ||
            deleteDocumentation.isLoading ||
            uploadDocumentationLink.isLoading
          }
        />
      )}
      <Box className="upload-documentation-container">
        {isUploading ? (
          <>
            {isScrapingEnabled && scrapeContent ? (
              <Box className="upload-documentation-container__box">
                <ScrapePreview
                  scrape={scrapeContent}
                  setScrapeContent={(content: string) => {
                    setScrapeContent({ ...scrapeContent, content });
                    setScrapingError(undefined);
                  }}
                  error={scrapingError}
                />
              </Box>
            ) : (
              <>
                <Text className="title">Upload API documentation to knowledge base</Text>
                <Box className="upload-documentation-container__box">
                  <UploadDocumentation
                    uploadedDocumentation={documentations}
                    onUploadDocumentation={onUploadDocumentation}
                    onDeleteDocumentation={onDeleteDocumentation}
                    setIsUploading={setIsUploading}
                    isModel={isModel}
                  />
                </Box>
              </>
            )}
          </>
        ) : (
          <UploadedDocumentationList
            uploadedDocumentation={documentations}
            onDeleteDocumentation={onDeleteDocumentation}
            isEditAllowed={isEdit}
            isEditing={isUploading}
            onClickEdit={() => setIsUploading(true)}
          />
        )}
      </Box>
      <>
        {isModel && <Divider my="md" className="confirmationDialog__divider" />}
        <Box className={`confirmationDialog__buttons_container`}>
          <Flex gap={isModel ? 32 : 10} className={`confirmationDialog__buttons`}>
            {isScrapingEnabled && scrapeContent ? (
              <>
                <Button
                  size={isModel ? 'md' : 'xs'}
                  fullWidth={true}
                  onClick={() => {
                    setScrapeContent(null);
                    setScrapingError(undefined);
                    setIsScrapingEnabled(false);
                  }}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  size={isModel ? 'md' : 'xs'}
                  fullWidth={true}
                  onClick={onClickScrapeDone}
                  variant="filled"
                  disabled={scrapingError !== undefined}
                >
                  Proceed
                </Button>
              </>
            ) : (
              <>
                {isModel && (
                  <Button size="md" onClick={onCancel} variant="filled">
                    Done
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Box>
      </>
    </Box>
  );
};
export default ResourceUploader;
