import {
  Box,
  TextInput,
  Title,
  useMantineTheme,
  Text,
  Textarea,
  Checkbox,
  Divider,
  Button,
  Flex,
} from '@mantine/core';
import { LangGraphRequest, UserStory } from '../../../../models/genie';
import { useStoryDialogStyles } from './style';
import UploadedDocumentationList from '../ResourceUploader/UploadedDocumentationList';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { userStoryService } from '../../../../service';
import { notifications } from '@mantine/notifications';
import { RootState } from '../../../../redux/common';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '../../../../redux/reducers';

export interface UserStoryProps {
  id: string;
  isReadOnly?: boolean;
  userStory: UserStory;
  isCreate: boolean;
  repoName: string;
  projectId: string;
  updateUserStory: (path: string, value: any) => void;
  onCancel: () => void;
  onClickSave: (request: LangGraphRequest | null) => void;
}
const UserStoryDialog: React.FC<UserStoryProps> = ({
  id,
  isCreate = false,
  isReadOnly,
  userStory,
  repoName,
  projectId,
  updateUserStory,
  onCancel,
  onClickSave,
}) => {
  const theme = useMantineTheme();
  const [nextStep, setNextStep] = useState<string | null>(null);
  const [prompt, setPrompt] = useState<string | null>(null);

  const { userData } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => state.authentication,
  );

  const getlangraphRequest = (request: LangGraphRequest) => {
    return {
      ...request,
      input: {
        ...request.input.messages.filter((m) => m.role !== 'user'),
        messages: [
          {
            role: 'user',
            content: prompt ?? '',
          },
        ],
      },
    };
  };

  const { data: langraphRequest } = useQuery({
    queryKey: [`load-user-promt-instruction`],
    queryFn: () =>
      userStoryService.getUserPromotByInstructionId(
        userData.organizationId,
        projectId,
        userStory.id,
        repoName,
      ),
    enabled: isReadOnly,
    onSettled: (data) => {
      if (data?.input) {
        setPrompt(data.input.messages.find((m) => m.role === 'user')?.content ?? '');
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });
  return (
    <Box sx={useStoryDialogStyles(theme)} id={id}>
      <div className="user-story-dialog">
        {isReadOnly ? (
          <>
            <Title order={5}>Create Jira Ticket</Title>
            <Text size={'xs'} mt={12} mb={12} fw={600}>
              <Text component="span" color="red">
                *{' '}
              </Text>
              Please review the saved details for this user story carefully. Once the jira ticket is
              created, you will not be able to modify these details through the developer portal
            </Text>
          </>
        ) : (
          <Title order={5}>{isCreate ? 'Create' : 'Edit'} User Story</Title>
        )}
        <>
          {nextStep === 'createTicket' ? (
            <>
              <Text className="input-lable">User Promt</Text>
              <Textarea
                className="input-field"
                value={prompt ?? ''}
                autosize
                minRows={5}
                maxRows={10}
                onChange={(event) => {
                  setPrompt(event.target.value);
                }}
              />
            </>
          ) : (
            <>
              <Text className="input-lable">User Story Name</Text>
              <TextInput
                className="input-field"
                placeholder="User Story Name"
                value={userStory.title}
                disabled={isReadOnly}
                onChange={(event) => {
                  updateUserStory('title', event.target.value);
                }}
              />
              <Text className="input-lable">Additional Information</Text>
              <Textarea
                className="input-field"
                placeholder="User Story Name"
                value={userStory.additionalInstructions}
                disabled={isReadOnly}
                onChange={(event) => {
                  updateUserStory('additionalInstructions', event.target.value);
                }}
              />
              <Checkbox
                style={{ marginTop: '15px', marginBottom: '15px' }}
                id={`${id}-generate-with-content`}
                checked={userStory.generateTicketWithContent}
                onChange={(event) =>
                  updateUserStory('generateTicketWithContent', event.currentTarget.checked)
                }
                disabled={isReadOnly}
                label={<span className="input-lable">Generate Jira with content</span>}
              />
              {isReadOnly && (
                <UploadedDocumentationList
                  uploadedDocumentation={userStory.references ?? []}
                  onDeleteDocumentation={() => {}}
                  isEditAllowed={false}
                  isEditing={false}
                  onClickEdit={() => {}}
                />
              )}
            </>
          )}
        </>
        <Divider my="md" className="confirmationDialog__divider" />
        <Flex gap={32} className={`confirmationDialog__buttons`}>
          <Button size="md" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            size="md"
            onClick={() => {
              if (isReadOnly && nextStep === null) {
                setNextStep('createTicket');
              } else {
                if (langraphRequest) onClickSave(getlangraphRequest(langraphRequest));
                else onClickSave(null);
              }
            }}
            variant="filled"
            disabled={userStory?.title?.length <= 0}
          >
            {isReadOnly ? (nextStep === 'createTicket' ? 'Create Ticket' : 'Next') : 'Save'}
          </Button>
        </Flex>
      </div>
    </Box>
  );
};
export default UserStoryDialog;
