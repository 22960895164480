import { Box, useMantineTheme, Text, Textarea } from '@mantine/core';
import React from 'react';
import { ScrapeContent } from '../../../../../models/genie';
import { previewStyle } from './style';
interface ScrapeProps {
  scrape: ScrapeContent;
  setScrapeContent: (content: string) => void;
  error?: string;
}
const ScrapePreview: React.FC<ScrapeProps> = ({ scrape, setScrapeContent, error }) => {
  const theme = useMantineTheme();

  return (
    <>
      <Box sx={previewStyle(theme)}>
        <Text fz={theme.fontSizes.sm} fw={500} c={theme.colors.gray[6]}>
          Document Preview
        </Text>
        <Textarea
          className="input-field"
          value={scrape.content}
          autosize
          minRows={5}
          maxRows={10}
          error={error}
          onChange={(event) => {
            setScrapeContent(event.target.value);
          }}
        />
      </Box>
    </>
  );
};
export default ScrapePreview;
