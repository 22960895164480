import { MantineTheme, Sx } from '@mantine/core';
import { UserStoryStatus } from '../../../models/genie';

export const userStoryGenieStyles = (theme: MantineTheme): Sx => {
  return {
    '.user-story-genie-paper': {
      padding: 0,
      border: 0,
      boxShadow: 'none',
      width: '100%',
      backgroundColor: 'transparent !important',
      '.user-story-genie-settings': {
        gap: theme.spacing.xs,
        backgroundColor: theme.colors.white[0],
        paddingTop: theme.spacing.xs,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        width: '100%',
        minHeight: '80vh',
      },
      '.user-story-genie-table-content': {
        backgroundColor: theme.colors.white[0],
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        width: '100%',
      },
    },
    '.jira-btn-group': {
      display: 'flex',
      alignItems: 'center',
    },
    '.user-story-create-button': {
      width: '150px',
    },
    '.user-story-add-button': {
      width: '150px',
    },
    '.epic-add-button': {
      width: '150px',
      marginRight: '4px',
    },
    '.input-lable': {
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
      color: theme.colors.gray[6],
      marginTop: theme.spacing.xs,
    },
  };
};
export const userStoryStatusStyles = (theme: MantineTheme, status: UserStoryStatus): Sx => {
  return {
    '.user-story-status': {
      color:
        status === UserStoryStatus.NEW || status === UserStoryStatus.UPDATED
          ? '#243FFA'
          : status === UserStoryStatus.JIRA_APPROVED || status === UserStoryStatus.JIRA_CREATED
          ? 'green'
          : '#FF0000',
      backgroundColor:
        status === UserStoryStatus.JIRA_APPROVED || status === UserStoryStatus.JIRA_CREATED
          ? 'rgba(0, 128, 0, 0.3)'
          : '',
      display: 'flex',
      alignItems:
        status === UserStoryStatus.JIRA_APPROVED || status === UserStoryStatus.JIRA_CREATED
          ? 'flex-start'
          : 'center',
      justifyContent:
        status === UserStoryStatus.JIRA_APPROVED || status === UserStoryStatus.JIRA_CREATED
          ? 'flex-start'
          : 'center',
      width: 'fit-content',
      borderRadius: theme.radius.sm,
      textAlign: 'center',
      paddingLeft: 4,
      paddingRight: 4,
    },
  };
};
export const bulkTicketCreateDialogStyles = (theme: MantineTheme): Sx => {
  return {
    '.confirmationDialog__buttons': {
      width: '100%',
      button: {
        width: '50%',
        '&:first-of-type': {
          borderColor: theme.colors.gray[5],
          color: theme.colors.gray[9],
        },
      },
      '&--primary button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.grubtech[0],
        },
      },
      '&--danger button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.red[6],
        },
      },
      '&--warning button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.yellow[6],
        },
      },
    },
  };
};
