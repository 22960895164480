import { notifications } from '@mantine/notifications';
import { userStoryAxios } from '../config/axios.config';
import {
  DocumentationType,
  EpicUserStory,
  LangGraphRequest,
  ScrapeContent,
  UserStory,
  UserStoryResponseStatus,
} from '../models/genie';

const userStoryService = {
  getUserStories: async (organizationId: string, projectId: string): Promise<EpicUserStory> => {
    const { data } = await userStoryAxios.get(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories`,
    );
    return data;
  },
  createJiraTickets: async (
    organizationId: string,
    projectId: string,
    userStoryIds: string[],
    repoName: string,
  ): Promise<UserStoryResponseStatus> => {
    const { data } = await userStoryAxios.post(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/create-tickets?userStoryIds=${userStoryIds}&repoName=${repoName}`,
    );
    return data;
  },
  createJiraTicket: async (
    organizationId: string,
    projectId: string,
    userStoryId: string,
    repoName: string,
    request: LangGraphRequest,
  ): Promise<UserStoryResponseStatus> => {
    const { data } = await userStoryAxios.post(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/user-stories/${userStoryId}/create-ticket`,
      request,
      {
        params: { repoName: repoName },
      },
    );
    return data;
  },
  createOrEditUserStory: async (
    organizationId: string,
    projectId: string,
    userStory: UserStory,
  ): Promise<UserStory> => {
    const patchDocument = [
      {
        op: 'replace',
        path: '/',
        value: userStory,
      },
    ];
    const { data } = await userStoryAxios.patch(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/user-stories?userStoryId=${userStory.id}`,
      patchDocument,
    );
    return data;
  },
  updateEpicId: async (
    organizationId: string,
    projectId: string,
    epicId: string,
  ): Promise<UserStory> => {
    const patchDocument = [
      {
        op: 'replace',
        path: '/epicId',
        value: epicId,
      },
    ];
    const { data } = await userStoryAxios.patch(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/user-stories`,
      patchDocument,
    );
    return data;
  },
  deleteUserStory: async (organizationId: string, projectId: string, userStoryId: string) => {
    const { data } = await userStoryAxios.delete(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/user-stories/${userStoryId}`,
    );
    return data;
  },
  approveUserStory: async (organizationId: string, projectId: string, userStoryId: string) => {
    const { data } = await userStoryAxios.put(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/user-stories/${userStoryId}/approve`,
    );
    return data;
  },
  uploadFile: async (
    organizationId: string,
    projectId: string,
    repoName: string,
    file: File,
    type: DocumentationType,
    userStoryId?: string,
  ): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await userStoryAxios.post(
        `/organizations/${organizationId}/projects/${projectId}/documentations/upload`,
        formData,
        {
          params: { type, repoName: repoName, userStoryId: userStoryId ?? '' },
        },
      );
      notifications.show({
        title: 'Success',
        message: 'Document uploaded successfully.',
        color: 'green',
      });
      return { status: data };
    } catch (error) {
      notifications.show({
        title: 'Something went wrong',
        message:
          error instanceof Error
            ? JSON.stringify((error as any).response.data.errorDescription) ??
              'Something went wrong'
            : 'Something went wrong',
        color: 'red',
      });
      const errorMessage =
        (error as any).response?.data?.errorDescription ?? 'Failed to upload file/link';
      return { status: false, message: errorMessage };
    }
  },
  scrape: async (link: string, fileName: string): Promise<ScrapeContent> => {
    const { data } = await userStoryAxios.post(`/documentations/scrape`, null, {
      params: { link, fileName },
    });
    return data;
  },
  uploadStringContent: async (
    organizationId: string,
    projectId: string,
    repoName: string,
    payload: ScrapeContent,
    type: DocumentationType,
    userStoryId?: string,
  ): Promise<boolean> => {
    const { data } = await userStoryAxios.post(
      `/organizations/${organizationId}/projects/${projectId}/documentations/upload-content`,
      payload,
      {
        params: { type, repoName: repoName, userStoryId: userStoryId ?? '' },
      },
    );
    return data;
  },
  deleteDocumentation: async (
    repoName: string,
    organizationId: string,
    projectId: string,
    filePath: string,
    userStoryId?: string,
  ): Promise<any> => {
    const { data } = await userStoryAxios.delete(
      `/organizations/${organizationId}/projects/${projectId}/documentations`,
      {
        data: filePath,
        params: { repoName, userStoryId: userStoryId ?? '' },
      },
    );
    return data;
  },
  getUserPromotByInstructionId: async (
    organizationId: string,
    projectId: string,
    userStoryId: string,
    repoName: string,
  ): Promise<LangGraphRequest> => {
    const { data } = await userStoryAxios.get(
      `/organizations/${organizationId}/projects/${projectId}/epic-user-stories/user-stories/${userStoryId}/user-prompt`,
      {
        params: { repoName },
      },
    );
    return data;
  },
};
export default userStoryService;
