import {
  Box,
  LoadingOverlay,
  Paper,
  TextInput,
  useMantineTheme,
  Text,
  Grid,
  Flex,
  Button,
  Tooltip,
  ActionIcon,
  Anchor,
} from '@mantine/core';
import { FC, useCallback, useState } from 'react';
import { userStoryGenieStyles, userStoryStatusStyles } from './style';
import { string as validateString, object as validateObject, addMethod } from 'yup';
import { useFormik } from 'formik';
import { Table } from '../../../components';
import { Documentation, LangGraphRequest, UserStory, UserStoryStatus } from '../../../models/genie';
import { useMutation, useQuery } from '@tanstack/react-query';
import userStoryService from '../../../service/userStory.service';
import { notifications } from '@mantine/notifications';
import { IconEdit, IconFileCheck, IconNotebook, IconTicket, IconTrash } from '@tabler/icons-react';
import { convertEnumToString } from '../../../utils/string';
import CustomeDialogBox from '../../../components/CustomDialogBox/CustomDialogBox';
import UserStoryDialog from './UserStoryDialog';
import { AuthenticationState } from '../../../redux/reducers';
import { RootState } from '../../../redux/common';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ResourceUploader from './ResourceUploader/ResourceUploader';
import UserStoryDocumentDialog from './UserStoryDocumentDialog';
import { StringSchema } from 'yup';

const JIRA_BASE_URL = 'https://grubtech.atlassian.net/browse/';

export interface UserStoryGenieProps {
  projectId: string;
  repoName: string;
}

declare module 'yup' {
  interface StringSchema {
    urlValidation(prefix: string, message: string): StringSchema;
  }
}

// Add a custom validation method to check for the prefix
addMethod<StringSchema>(
  validateString,
  'urlValidation',
  function (prefix: string, message: string) {
    return this.test('urlValidation', message, function (value) {
      const { path, createError } = this;
      const prefixedValue = prefix + value;
      return (
        (prefixedValue.startsWith(prefix) && validateString().url().isValidSync(prefixedValue)) ||
        createError({ path, message: message ?? `Must start with ${prefix} and be a valid URL` })
      );
    });
  },
);

const UserStoryGenie: FC<UserStoryGenieProps> = ({ projectId, repoName }) => {
  const theme = useMantineTheme();

  const { userData } = useSelector<RootState, AuthenticationState>(
    (state: RootState) => state.authentication,
  );

  const [userStories, setUserStories] = useState<UserStory[]>([]);
  const [selectedUserStories, setSelectedUserStories] = useState<UserStory[]>([]);
  const [openedUserStory, setOpenedUserStory] = useState<UserStory | null>(null);
  const [isCreate, setIsCreate] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [savedEpic, setSavedEpic] = useState<string>('');
  const [globleDocs, setGlobleDocs] = useState<Documentation[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isCreatJira, setIsCreatJira] = useState<boolean>(false);
  const [isUploadingDocs, setIsUploadingDocs] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isLoading: isLoadingUserStories, refetch } = useQuery({
    queryKey: [`load-all-user-stories-for-${projectId}`, userData.organizationId, projectId],
    queryFn: () => userStoryService.getUserStories(userData.organizationId, projectId),
    enabled: !!projectId,
    onSuccess: (data) => {
      setUserStories(data.userStories);
      setSavedEpic(data.epicId ?? '');
      setGlobleDocs(data.references ?? []);
      setFieldValue('epicLink', data.epicId);
      if (isUploadingDocs && openedUserStory) {
        setOpenedUserStory({
          ...openedUserStory,
          references: data.userStories.find((s) => s.id === openedUserStory.id)?.references,
        });
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const createJira = useMutation({
    mutationFn: (payload: { id: string; request: LangGraphRequest }) => {
      return userStoryService.createJiraTicket(
        userData.organizationId,
        projectId,
        payload.id,
        repoName,
        payload.request,
      );
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: 'Jira ticket created successfully.',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const updateUserStory = useMutation({
    mutationFn: (payload: UserStory) => {
      return userStoryService.createOrEditUserStory(userData.organizationId, projectId, payload);
    },
    onSuccess: () => {
      refetch();
      notifications.show({
        title: 'Success',
        message: `Successfully ${isCreate ? 'create' : 'update'} the user story.`,
        color: 'green',
      });
      onClickClose();
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });
  const deleteUserStory = useMutation({
    mutationFn: (id: string) => {
      return userStoryService.deleteUserStory(userData.organizationId, projectId, id);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: 'Successfully delete the user story.',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const approveUserStory = useMutation({
    mutationFn: (id: string) => {
      return userStoryService.approveUserStory(userData.organizationId, projectId, id);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: 'Successfully approved the user story.',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const updateEpicId = useMutation({
    mutationFn: (id: string) => {
      return userStoryService.updateEpicId(userData.organizationId, projectId, id);
    },
    onSuccess: () => {
      refetch();
      onClickClose();
      notifications.show({
        title: 'Success',
        message: 'Successfully update the epic user story.',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  // useEffect(() => {
  //   setIsAllChecked(
  //     userStories
  //       .filter((s) => !isUserStoryCreatedOrApproved(s.ticketStatus))
  //       .every((s) => selectedUserStories.includes(s)),
  //   );
  // }, [userStories, selectedUserStories]);

  const { values, setFieldValue, handleBlur, touched, errors } = useFormik({
    initialValues: {
      epicLink: '',
    },
    onSubmit: () => {},
    validationSchema: validateObject().shape({
      epicLink: validateString()
        .trim()
        .urlValidation(JIRA_BASE_URL, 'Please enter valid Jira Epic Link.')
        .required('Required'),
    }),
    validateOnMount: true,
  });

  const onClickClose = () => {
    setOpenedUserStory(null);
    setIsReadOnly(false);
    setSelectedUserStories([]);
    setIsCreatJira(false);
    setIsCreate(false);
    setIsUploadingDocs(false);
  };

  const handleEditUserStory = (userStory: UserStory) => {
    setOpenedUserStory(userStory);
  };
  const handleUserStoryDocuments = (userStory: UserStory) => {
    setIsUploadingDocs(true);
    setOpenedUserStory(userStory);
  };

  const handleCreateJiraTicket = (userStory: UserStory) => {
    setIsCreatJira(true);
    setOpenedUserStory(userStory);
    setIsReadOnly(true);
  };

  const handleDeleteUserStory = (id: string) => {
    deleteUserStory.mutate(id);
  };

  const handleApproveUserStory = (id: string) => {
    approveUserStory.mutate(id);
  };

  const handleAddUserStory = () => {
    setOpenedUserStory({
      id: uuidv4(),
      title: '',
      additionalInstructions: '',
      jiraTicketId: '',
      assignee: '',
      ticketStatus: UserStoryStatus.NEW,
      references: [],
      generateTicketWithContent: false,
    });
    setIsCreate(true);
    setIsReadOnly(false);
  };

  const isUserStoryCreatedOrApproved = (status: UserStoryStatus) => {
    return (
      status === UserStoryStatus.JIRA_APPROVED ||
      status === UserStoryStatus.JIRA_CREATED ||
      status === UserStoryStatus.CREATE_REQUESTED
    );
  };
  const isAtLeastOneUserStoryCreatedOrApproved = (stories: UserStory[]) => {
    return stories.some((s) => isUserStoryCreatedOrApproved(s.ticketStatus));
  };

  const statusLable = (status: UserStoryStatus) => {
    return (
      <Box sx={userStoryStatusStyles(theme, status)}>
        <Text className="user-story-status" align="center">
          {convertEnumToString(status)}
        </Text>
      </Box>
    );
  };
  const getUserStories = useCallback(() => {
    return userStories.map((story, index) => [
      index + 1,
      story.title,
      statusLable(story.ticketStatus),
      story.jiraTicketId ? (
        <Anchor href={JIRA_BASE_URL + story.jiraTicketId} target="_blank">
          {story.jiraTicketId}
        </Anchor>
      ) : (
        '--'
      ),
      <Flex align={'end'} gap={theme.spacing.xl} direction={'column'}>
        <Grid>
          <Grid.Col span={2}></Grid.Col>
          <Grid.Col span={2}>
            <Tooltip label="Edit" withArrow position="bottom">
              <ActionIcon
                variant="transparent"
                onClick={() => handleEditUserStory(story)}
                disabled={isUserStoryCreatedOrApproved(story.ticketStatus)}
              >
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={2}>
            <Tooltip label="Documents" withArrow position="bottom">
              <ActionIcon
                variant="transparent"
                onClick={() => handleUserStoryDocuments(story)}
                disabled={isUserStoryCreatedOrApproved(story.ticketStatus)}
              >
                <IconNotebook />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={2}>
            <Tooltip label="Jira create" withArrow position="bottom">
              <ActionIcon
                variant="transparent"
                onClick={() => handleCreateJiraTicket(story)}
                disabled={isUserStoryCreatedOrApproved(story.ticketStatus)}
              >
                <IconTicket />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={2}>
            <Tooltip label="Delete user story" withArrow position="bottom">
              <ActionIcon
                variant="transparent"
                onClick={() => handleDeleteUserStory(story.id)}
                disabled={isUserStoryCreatedOrApproved(story.ticketStatus)}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
          <Grid.Col span={2}>
            <Tooltip label="Approve" withArrow position="bottom">
              <ActionIcon
                variant="transparent"
                onClick={() => handleApproveUserStory(story.id)}
                disabled={story.ticketStatus !== UserStoryStatus.JIRA_CREATED}
              >
                <IconFileCheck />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
        </Grid>
      </Flex>,
    ]);
  }, [userStories, selectedUserStories]);

  const userStoryComponent: React.ReactNode = (
    <UserStoryDialog
      key={`user-story-dialog-${openedUserStory?.id}`}
      id={openedUserStory?.id ?? ''}
      isCreate={isCreate}
      isReadOnly={isReadOnly}
      userStory={openedUserStory ?? ({} as UserStory)}
      projectId={projectId}
      repoName={repoName}
      onCancel={onClickClose}
      updateUserStory={(path, value) => {
        if (openedUserStory) {
          setOpenedUserStory({ ...openedUserStory, [path]: value });
        }
      }}
      onClickSave={(request: LangGraphRequest | null) => {
        if (openedUserStory) {
          if (isCreatJira && request)
            createJira.mutate({ id: openedUserStory.id, request: request });
          else updateUserStory.mutate(openedUserStory);
        }
      }}
    />
  );
  const userStoryDocument: React.ReactNode = (
    <UserStoryDocumentDialog
      key={`user-story-document-dialog-${openedUserStory?.id}`}
      id={openedUserStory?.id ?? ''}
      isEdit={true}
      projectId={projectId}
      refetch={refetch}
      repoName={repoName}
      userStory={openedUserStory ?? ({} as UserStory)}
      isReadOnly={isReadOnly}
      isLoading={(load: boolean) => setIsLoading(load)}
      onCancel={onClickClose}
    />
  );
  return (
    <Box sx={userStoryGenieStyles(theme)}>
      <LoadingOverlay
        visible={
          isLoadingUserStories ||
          createJira.isLoading ||
          updateUserStory.isLoading ||
          deleteUserStory.isLoading ||
          approveUserStory.isLoading ||
          updateEpicId.isLoading ||
          isLoading
        }
      />
      <Paper shadow="xs" className="user-story-genie-paper">
        <Grid className="user-story-genie-settings">
          <Grid.Col span={12}>
            {!isUploading && (
              <>
                <Text fz={theme.fontSizes.md} fw={700} mt={10} mb={10}>
                  Get Started
                </Text>
                <Text className="input-lable">Jira Epic Link</Text>
                <TextInput
                  placeholder="Jira Epic Link"
                  value={values.epicLink}
                  className="name-input"
                  name="epicLink"
                  onChange={(e) => setFieldValue('epicLink', e.target.value.trimStart())}
                  onBlur={(e) => {
                    setFieldValue('epicLink', e.target.value.trimEnd());
                    handleBlur(e);
                  }}
                  error={touched.epicLink && errors.epicLink ? errors.epicLink : null}
                  icon={<Text color="dimmed">{JIRA_BASE_URL}</Text>}
                  iconWidth={300}
                  rightSectionWidth={150}
                  rightSection={
                    <Button
                      size="xs"
                      variant="filled"
                      className="epic-add-button"
                      onClick={() => updateEpicId.mutate(values.epicLink)}
                      disabled={
                        !!errors.epicLink ||
                        userStories?.some((u) => isUserStoryCreatedOrApproved(u.ticketStatus))
                      }
                    >
                      {savedEpic ? 'Update' : 'Save'}
                    </Button>
                  }
                />
              </>
            )}
            {savedEpic && (
              <>
                <ResourceUploader
                  repoName={repoName}
                  documentations={globleDocs}
                  setIsUploading={(val: boolean) => setIsUploading(val)}
                  projectId={projectId}
                  isUploading={isUploading}
                  refetch={refetch}
                  isEdit={!isAtLeastOneUserStoryCreatedOrApproved(userStories)}
                />
                {!isUploading && (
                  <>
                    <Flex justify="space-between" mt={12}>
                      <Text className="input-lable">User Stories</Text>
                      <Box className="jira-btn-group">
                        <Button
                          fullWidth
                          mr={4}
                          size="xs"
                          variant="filled"
                          className="user-story-add-button"
                          onClick={handleAddUserStory}
                        >
                          + Add User Story
                        </Button>
                      </Box>
                    </Flex>
                    <Box mt={4}>
                      <Table
                        id="user-story-genie-table"
                        tableHeader={[
                          { label: '#', id: 'user-story-number' },
                          { label: 'User Story Title', id: 'user-story-title' },
                          { label: 'Status', id: 'status' },
                          { label: 'Jira Link', id: 'jira-link' },
                          { label: '', id: 'action-button' },
                        ]}
                        data={getUserStories()}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Grid.Col>
        </Grid>
        {openedUserStory && (
          <CustomeDialogBox
            id="user-story-dialog-box"
            isOpen={openedUserStory !== null}
            onClose={() => onClickClose()}
            component={isUploadingDocs ? userStoryDocument : userStoryComponent}
          />
        )}
      </Paper>
    </Box>
  );
};

export default UserStoryGenie;
